import React, { lazy, useMemo, Suspense } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Carousel, FuzzySearch, Layout, SEO } from 'components'

import { HERO_SLIDES } from 'constants/heroConstants'
import {
  PAGE_HEADER,
  TUTORIAL_NAMES,
} from 'constants/interactiveTutorialConstants'
import { isBrowser } from 'constants/utils'

const useStyles = makeStyles(theme => ({
  alignCenter: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .MuiButton-root:hover': {
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: theme.palette.text.white,
    },
  },
  buttonSize: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.white,
    fontWeight: 'bold',
    marginTop: '16px',
    textTransform: 'none',
    width: '200px',
  },
}))

const InteractiveTutorial = lazy(() =>
  import('../components/InteractiveTutorial/InteractiveTutorial')
)

const DesignsPage = ({ animation }) => {
  const homePageStyles = useStyles()
  const loggedUser = useSelector(state => state.userState.user)

  const showInteractiveTutorial =
    loggedUser &&
    !loggedUser.isAnonymous &&
    loggedUser.tutorial_state &&
    !loggedUser.tutorial_state.pageHeader &&
    isBrowser()

  const HERO_SLIDES_MEMO = useMemo(() => HERO_SLIDES, [])

  return (
    <Layout>
      <SEO title="Home" />
      <div className={homePageStyles.alignCenter}>
        <Carousel
          animation={animation}
          hoverNavButtons={true}
          indicators={false}
          slides={HERO_SLIDES_MEMO}
        />

        {showInteractiveTutorial && (
          <Suspense fallback={<div />}>
            <InteractiveTutorial
              showTutorial={!loggedUser.tutorial_state.pageHeader}
              steps={PAGE_HEADER}
              tutorial={TUTORIAL_NAMES.PAGE_HEADER}
            />
          </Suspense>
        )}
        <FuzzySearch />
      </div>
    </Layout>
  )
}

export default DesignsPage
