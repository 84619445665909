import hero1 from 'assets/hero_images/heroImage1.jpg'
import hero2 from 'assets/hero_images/heroImage2.jpg'
import hero3 from 'assets/hero_images/heroImage3.jpg'

export const HERO_SLIDES = [
  {
    description:
      "A New 3D Print Community is Coming Together, and it's all in your hands...",
    disabled: false,
    image_url: hero2,
    isActive: true,
    title: 'Community Driven 3D Printing  ',
  },
  {
    description:
      'The one stop shop for all of your 3D printing needs made easy ',
    disabled: false,
    image_url: hero1,
    isActive: true,
    title: 'Print your Imagination  ',
  },
  {
    description:
      'Designers and Makers now have a platform to fuel their ambitions',
    disabled: false,
    image_url: hero3,
    isActive: true,
    title: 'Run your business with us ',
  },
]
